/*! OE-Templates-6.74 */

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/OpenSans-Regular.eot);
	src: local('Open Sans Regular'), local('OpenSans-Regular'), url(../fonts/OpenSans-Regular.eot?#iefix) format('embedded-opentype'), url(../fonts/OpenSans-Regular.woff2) format('woff2'), url(../fonts/OpenSans-Regular.woff) format('woff'), url(../fonts/OpenSans-Regular.ttf) format('truetype'), url(../fonts/OpenSans-Regular.svg#OpenSans) format('svg');
	unicode-range: U +0000 -00FF, U +0131, U +0152 -0153, U +02 BB-02BC, U +02 C6, U +02 DA, U +02 DC, U +2000 -206F, U +2074, U +20 AC, U +2122, U +2191, U +2193, U +2212, U +2215, U +FEFF, U +FFFD
}
@font-face {
	font-family: 'Open Sans';
	font-style: bold;
	font-weight: 700;
	src: url(../fonts/OpenSans-Bold.eot);
	src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/OpenSans-Bold.eot?#iefix) format('embedded-opentype'), url(../fonts/OpenSans-Bold.woff2) format('woff2'), url(../fonts/OpenSans-Bold.woff) format('woff'), url(../fonts/OpenSans-Bold.ttf) format('truetype'), url(../fonts/OpenSans-Bold.svg#OpenSans) format('svg');
	unicode-range: U +0000 -00FF, U +0131, U +0152 -0153, U +02 BB-02BC, U +02 C6, U +02 DA, U +02 DC, U +2000 -206F, U +2074, U +20 AC, U +2122, U +2191, U +2193, U +2212, U +2215, U +FEFF, U +FFFD
}

@font-face {
	font-family: 'Sansa Pro';
	src: url(../fonts/SansaPro-NormalItalic.eot);
	src: local('Sansa Pro NormalItalic'), url('../fonts/SansaPro-NormalItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/SansaPro-NormalItalic.woff') format('woff'), url('../fonts/SansaPro-NormalItalic.woff2') format('woff2'), url('../fonts/SansaPro-NormalItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: 400;
}

.features .item, .features p, .features p a {
	font-family: 'Open Sans', Arial, Helvetica, sans-serif
}

.features h3,
.features .heading{
	font-family: 'Sansa Pro', Arial, Helvetica, sans-serif
}

.features .textblock, .features a.imglink img {
	vertical-align: middle
}

.features {
	margin: 0 auto;
    padding: 40px 25px 0 25px;
}


.features .item {
	padding: 20px;
	background-color: var(--clr-ci);
    border-bottom: 1px solid #c0d3e5;
	color: #FFF;
	display: inline-block;
	line-height: 298px;
	vertical-align: top;
	margin: 0 !important
}
.features .blockleft {
	float: left;
	width: 50%;
	height: 300px
}
.features .blockright {
	float: right;
	width: 50%;
	height: 300px
}
.features .textblock {
	line-height: normal;
	display: inline-block;
	width: 100%
}
.features h3,
.features .heading{
	font-size: 42px;
	font-style: italic;
	font-weight: 400;
	margin: 0 0 12px;
	line-height: 1.1
}
.features p {
	margin: 6px 0;
	font-size: 16px
}
.features a.imglink {
	display: block;
	background-color: transparent !important;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	border: none;
	outline: 0;
	text-align: center
}
.features a {
	color: #FFF;
	text-decoration: none
}
.features a.toweb {
	display: block;
	margin-top: 25px
}
.features a:hover {
	text-decoration: underline
}

.features.regio {
    margin: 0 auto;
    padding: 25px 25px 0 25px;
}

.features.regio .item{
    border-bottom: none;
}


/* Slider */
.ctnB-slider{
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
         box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.ctnB-list{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;

}

.ctnB-list:focus{
    outline: none;
}

.ctnB-list.dragging{
    cursor: pointer;
    cursor: hand;
}

.ctnB-slider .ctnB-track,.ctnB-slider .ctnB-list{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.ctnB-track{
    position: relative;
    top: 0;
    left: 0;
    display: block;
}

.ctnB-track:before,.ctnB-track:after{
    display: table;
    content: '';
}

.ctnB-track:after{clear: both;}

.ctnB-loading .ctnB-track{visibility: hidden;}

[dir='rtl'] .ctnB-slide{float: right;}

.ctnB-slide img{display: block;}

.ctnB-slide.ctnB-loading img{display: none;}

.ctnB-slide.dragging img{pointer-events: none;}

.ctnB-initialized .ctnB-slide{display: block;}

.ctnB-loading .ctnB-slide{visibility: hidden;}

.ctnB-vertical .ctnB-slide{
    display: block;
    height: auto;
    border: 1px solid transparent;
}



/** Banner **/
	
.contentBanner{
	position: relative;
	display: block;
	overflow:hidden;
	padding-left: 0 !important;
	padding-right: 0 !important; 
	line-height: 20px !important; 
	    width: 823px;
}

.contentBanner .overlay{
	transform: rotate(-4deg);	
}

.contentBanner .overlay.blockleft{
    height: 220px !important;
}

.contentBanner h3{
	position: relative;
	display: block; 
	padding: 10px 10px 10px 25px;
	font-size: 33px;
	margin: 0;
	background: var(--clr-ci);
}

.contentBanner .textblock{
	position: relative;
	right: 5px;
	display: block;  
	width: auto !important;
	padding: 15px 10px 15px 25px;
	margin: 0;
	font-size: 16px;
	color: var(--clr-ci);
	background: #ffffff;
}

.contentBanner a{
	position: relative;
	display: inline;
	float: right;
	left: 2px;
	margin: 0;
	padding: 10px 10px 10px 25px;
	font: 14px 'Open Sans', Arial, Helvetica, sans-serif;
	color: #FFFFFF;
	background: var(--clr-ci) !important;
}

.contentBanner a:before{
	content: ">";
	position: absolute;
	display: block;
	width: 10px;
	height: 10px;
	left: 10px;
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: bold;
	color: #FFFFFF;
	background: var(--clr-ci);
}

.carousel_wrapper{
	  margin: 30px auto 0;
  width:100%;
  max-width:865px;
  border-bottom: 1px solid #c0d3e5;
  padding: 0 20px 30px;
  box-sizing: border-box;
}

.carousel{
	border: 1px solid #fff;
	  box-sizing: border-box;
}

.carousel .bannerItem {
  position: relative;
}

.carousel .bannerItem .imageContainer {
  display:block;
  width:100%;
  position:relative;
  max-height:400px;
  	
}

.carousel .bannerItem img {
  display:block;
  width:100%;
}

.carousel .bannerItem .imageContainer:after {
  display:block;
  position: absolute;
  bottom: 5px;
  right: 7px;
  content: attr(data-copyright-text);
  font: 9px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #FFFFFF;
  text-shadow: 0 0 5px #000000;
}

.carousel .bannerItem .overlay {
  z-index: 999;
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  color:#fff;
  padding: 30px 0 70px 0;
  width: 55%;
}

.ctnB-slide{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

/* Arrows */
.ctnB-prev,.ctnB-next{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.ctnB-prev:hover,.ctnB-prev:focus,
.ctnB-next:hover,.ctnB-next:focus{
    color: transparent;
    outline: none;
    background: transparent;
}

.ctnB-prev:hover:before,.ctnB-prev:focus:before,
.ctnB-next:hover:before,.ctnB-next:focus:before{
    opacity: 1;
}

.ctnB-prev.ctnB-disabled:before,.ctnB-next.ctnB-disabled:before{
    opacity: .25;
}

.ctnB-prev:before,.ctnB-next:before{
    font-family: 'ctnB';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ctnB-prev{left: -25px;}
[dir='rtl'] .ctnB-prev{
    right: -25px;
    left: auto;
}
.ctnB-prev:before{content: '←';}
[dir='rtl'] .ctnB-prev:before{content: '→';}

.ctnB-next{right: -25px;}
[dir='rtl'] .ctnB-next{
    right: auto;
    left: -25px;
}
.ctnB-next:before{content: '→';}
[dir='rtl'] .ctnB-next:before{content: '←';}

/* Dots */
.ctnB-dots{
    position: absolute;
    bottom: 30px;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
}

.ctnB-dots li{
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 10px;
    padding: 0;
    cursor: pointer;
}

.ctnB-dots li button{
    text-indent: -9999px;
    display: block;
    width: 16px;
    height: 16px;
    padding: 5px;
    cursor: pointer;
    outline: none;
    border: 0;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0 0 2px #000, 0 0 4px rgba(0,0,0,.5);
    cursor: pointer;
    transition: all .15s;
}

.ctnB-dots li button:hover,.ctnB-dots li button:focus{
    outline: none;
}

.ctnB-dots li button:hover:before,.ctnB-dots li button:focus:before{
    opacity: 1;
}

.ctnB-dots li.ctnB-active button{
    background: var(--clr-ci);
}

.legend{
margin-top: 10px;
}

@media screen and (min-width: 1680px) {
	
	.carousel_wrapper{
	max-width: 955px;
	}
	
	.contentBanner{
	width: 915px;
	}

	.carousel .bannerItem .overlay{
	width: 50%;
	}
}